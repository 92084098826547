export default {
  listado: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Auth/Login'),
    },
    {
      path: '/',
      name: 'Principal',
      component: () => import('@/layouts/Container'),
      redirect: { name: 'Inicio' },
      meta: { requiresAuth: true },
      children: [
        {
          path: 'dashboard',
          name: 'Inicio',
          component: () => import('@/views/Dashboard/Index'),
        },
        {
          path: 'beneficiarios',
          meta: { label: 'Beneficiarios' },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              name: 'beneficiarios',
              path: '',
              component: () =>
                import('@/views/Beneficiarios/BeneficiariosTable'),
            },
            {
              path: 'create',
              name: 'Nuevo Beneficiario',
              component: () =>
                import('@/views/Beneficiarios/BeneficiariosCreate'),
            },
            {
              path: 'edit/:id',
              name: 'Editar Beneficiario',
              component: () =>
                import('@/views/Beneficiarios/BeneficiariosEdit'),
            },
            {
              path: 'bloquear/:id',
              name: 'Baja Beneficiario',
              component: () =>
                import('@/views/Beneficiarios/BeneficiariosBloquear'),
            },
            {
              path: 'adherente',
              name: 'Agregar adherente',
              component: () =>
                import('@/views/Beneficiarios/BeneficiariosAddAdherente'),
              props: true,
            },
            {
              path: 'alta',
              name: 'Confirmar Alta',
              component: () =>
                import('@/views/Beneficiarios/BeneficiariosAlta'),
              props: true,
            },
          ],
        },
        {
          path: 'lotes',
          redirect: '/lotes',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          meta: { is_admin: true },
          children: [
            {
              meta: { label: 'Lotes' },
              name: 'Lista de Lotes',
              path: '',
              component: () => import('@/views/Lotes/LotesTable'),
            },
          ],
        },
        {
          path: 'bajas',
          redirect: '/bajas',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          meta: { is_admin: true },
          children: [
            {
              meta: { label: 'Bajas' },
              name: 'Lotes de Bajas',
              path: '',
              component: () => import('@/views/Bajas/BajasTable'),
            },
          ],
        },
        // {
        //   path: 'bloqueos',
        //   redirect: '/bloqueos',
        //   component: {
        //     render(c) {
        //       return c('router-view')
        //     },
        //   },
        //   meta: { is_admin: true },
        //   children: [
        //     {
        //       meta: { label: 'Bloqueos' },
        //       name: 'Lotes de Bloqueos',
        //       path: '',
        //       component: () => import('@/views/Bloqueos/BloqueosTable'),
        //     },
        //   ],
        // },
        {
          path: 'changepass',
          name: 'changepass',
          component: () => import('@/views/Auth/ChangePass'),
        },
        {
          path: 'gestion',
          meta: { label: 'Gestion', is_super: true },
          redirect: '/gestion/usuarios',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: 'usuarios',
              meta: { label: 'Usuarios', is_admin: true },
              component: {
                render(c) {
                  return c('router-view')
                },
              },
              children: [
                {
                  name: 'Listado de Usuarios',
                  path: '',
                  component: () => import('@/views/Usuarios/UsuariosTable'),
                },
                {
                  path: 'create',
                  name: 'Nuevo Usuario',
                  component: () => import('@/views/Usuarios/UsuariosCreate'),
                },
                {
                  path: 'edit/:id',
                  name: 'Editar Usuario',
                  component: () => import('@/views/Usuarios/UsuariosEdit'),
                },
                {
                  path: 'clearpass/:id',
                  name: 'Blanquear Usuario',
                  component: () => import('@/views/Usuarios/UsuariosClearPass'),
                },
              ],
            },
            {
              path: 'items',
              meta: { label: 'Items', is_admin: true },
              component: {
                render(c) {
                  return c('router-view')
                },
              },
              children: [
                {
                  name: 'Listado',
                  path: '',
                  component: () => import('@/views/Items/ItemsTable'),
                },
                {
                  path: 'create',
                  name: 'Nuevo Item',
                  component: () => import('@/views/Items/ItemsCreate'),
                },
                {
                  path: 'edit/:id',
                  name: 'Editar Item',
                  component: () => import('@/views/Items/ItemsEdit'),
                },
              ],
            },
            {
              path: 'selects',
              redirect: '/selects',
              component: {
                render(c) {
                  return c('router-view')
                },
              },
              meta: { is_admin: true },
              children: [
                {
                  meta: { label: 'Selects' },
                  name: 'Areas y Lugares',
                  path: '',
                  component: () => import('@/views/Selects/SelectsTable'),
                },
              ],
            },
            {
              path: 'logs',
              name: 'Logs Sistema',
              component: () => import('@/views/Logs/LogsTable'),
            },
          ],
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/System/PageNotFound'),
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/Auth/Logout'),
    },
  ],
}
