import Vue from 'vue'
import { API } from '@/plugins/api'
import { utils, writeFileXLSX } from 'xlsx'

Vue.prototype.$sendToast = function (tipo, texto, posicion = null) {
  if (posicion == null) {
    posicion = 'top-right'
  }
  this.$toast(texto, {
    type: tipo,
    timeout: 5000,
    position: posicion,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    closeButton: 'button',
    icon: true,
    rtl: false,
  })
}
Vue.prototype.$validateState = function (ref) {
  if (
    this.veeFields[ref] &&
    (this.veeFields[ref].dirty || this.veeFields[ref].validated)
  ) {
    return !this.errors.has(ref)
  }
  return null
}
Vue.prototype.$fetchVuetable = async function (apiUrl, httpOptions) {
  const datos = await API.get(apiUrl, httpOptions)
  return datos.data
}
Vue.prototype.$makeExcel = async function (endpoint, filename, form) {
  try {
    const datos = await API.post(endpoint, form)
    const data = utils.json_to_sheet(datos.data.data)
    const workbook = utils.book_new()

    utils.book_append_sheet(workbook, data, filename)
    writeFileXLSX(workbook, `${filename}.xlsx`)
  } catch (error) {
    this.$sendToast(
      'error',
      'No se ejecuto la accion - ' + error.response.data.message
    )
  }
}
